import React from "react";
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import {Layout, Spin} from "antd";
import CreateCharacterForm from "../CreateCharacter/CreateCharacter";
import GameSideMenu from "./GameSideMenu";
import GameContent from "./GameContent";

const { Content } = Layout;

export default class Game extends React.Component<any, any> {

  state = {
    initialLoad: false,
    hasError: false,
    character: null,
    chatIsVisible: false,
  };

  componentDidMount() {
    this.getCharacterInfo();
  }

  toggleChat = () => {
    this.setState({
      chatIsVisible: !this.state.chatIsVisible,
    });
  };

  async getCharacterInfo() {
    try {
      const session = await Auth.currentSession();
      const jwtToken = session.getIdToken().getJwtToken();
      let apiName = 'AccountService';
      let path = '/character';
      let myInit = {
        headers: { Authorization: jwtToken }
      };
      const response = await API.get(apiName, path, myInit);
      const { character } = response;
      this.setState({ character, initialLoad: true, hasError: false });
    } catch (e) {
      console.error(e);
      this.setState({ initialLoad: true, hasError: true });
    }
  }

  renderContent = () => {
    const { character, initialLoad, hasError, chatIsVisible } = this.state;

    if (!initialLoad) {
      return (
        <div className="App">
          <header className="App-header">
            <Spin size="large" />
          </header></div>
      );
    }

    if (hasError) {
      return <div>Dunno</div>;
    }

    return character ? <GameContent chatIsVisible={chatIsVisible} onCloseChat={this.toggleChat} /> : <CreateCharacterForm />;

  };

  render() {
    return (
      <Layout>
        <Layout>
          <GameSideMenu toggleChat={this.toggleChat} />
          <Layout>
            <Content>
              <div className="App">
                <div className="App-header" style={{ overflow: 'hidden', position: 'relative' }}>
                  {this.renderContent()}
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
