import React from "react";
import {Button, Icon, Menu} from "antd";
import Chat from "../Chat/Chat";

export default class GameContent extends React.Component<any, any> {
  state = {
    current: 'all',
    chatOpen: false,
    fightMessage: '',
  };

  handleClick = (e: any) => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  generateFightMessage = () => {
    const messages = [
      "You died a slow painful death.",
      "You died walking off a cliff.",
      "You got hit by a horse and died.",
      "You got the measles and died.",
      "You got stung by something and died.",
      "A squirrel dropped an acorn on your head and you died.",
      "A cat scratched you and you died.",
      "You tripped and died.",
      "You coughed and died.",
      "<censored>, and then you died.",
      "An arrow shot you through the knee and you died.",
      "You slayed a dragon, but as you were collecting the loot you suddenly died."
    ];

    const chosenMessage = Math.floor(Math.random() * messages.length);
    const fightMessage = messages[chosenMessage];
    this.setState({ fightMessage });
  };

  toggleChat = () => { this.setState({ chatOpen: !this.state.chatOpen }); };

  render() {
    const { fightMessage } = this.state;
    const { chatIsVisible, onCloseChat } = this.props;
    return (
      <React.Fragment>
          <div style={{marginTop: '40vh' }} />
          <Button onClick={this.generateFightMessage} type="primary">Fight</Button>
          <div>{fightMessage}</div>
          <div style={{ marginTop: 'auto', width: '100%', textAlign: 'initial', backgroundColor: 'white' }}>
            <Menu            theme="dark"
                              onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
              <Menu.Item key="all">
                <Icon type="heart" />
                <span>All</span>
              </Menu.Item>
              <Menu.Item key="global">
                <Icon type="smile" />
                <span>Global</span>
              </Menu.Item>
              <Menu.Item key="guild">
                <Icon type="like" />
                <span>Guild</span>
              </Menu.Item>
              <Menu.Item key="party">
                <Icon type="fire" />
                <span>Party</span>
              </Menu.Item>
            </Menu>
            <div style={{ height: this.state.chatOpen ? '250px' : '0px', backgroundColor: '#282c34', transition: 'all 0.2s', position: 'relative' }}>CHAT BODY</div>
            <div
              style={{ paddingBottom: this.state.chatOpen ? '298px' : '0px', color: 'black', height: '48px', width: '48px', lineHeight: '40px', position: 'fixed', right: 0, bottom: 0, textAlign: 'center', cursor: 'pointer', transition: 'all 0.2s' }}
              onClick={this.toggleChat}
            >
              <Icon style={{ fontSize: '20px', color: 'white' }} type={this.state.chatOpen ? "down" : "up"} />
            </div>
          </div>
          {/*<Chat chatIsVisible={chatIsVisible} onCloseChat={onCloseChat} />*/}
      </React.Fragment>
    );
  }
}
