import React from 'react';
import Amplify from '@aws-amplify/core';
import {
  AuthPiece,
  Authenticator,
  Greetings,
  ConfirmSignIn,
  ConfirmSignUp,
  RequireNewPassword,
  VerifyContact,
  ForgotPassword,
  TOTPSetup,
  SignUp,
  SignIn
} from 'aws-amplify-react';
import Game from "./Game/Game";
import {UsernameAttributes} from "aws-amplify-react/lib/Auth/common/types";
import './App.css';

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_zUrrR7nZQ',
    userPoolWebClientId: '2nassebtc8ksufaton0ul1s6d9'
  },
  API: {
    endpoints: [
      {
        name: 'AccountService',
        endpoint: 'https://eh9dk77xta.execute-api.us-west-2.amazonaws.com/prod',
      }
    ],
  }
});

class App extends AuthPiece<any, any> {

  constructor(props: any) {
    super(props);
    this._validAuthStates = ['signedIn', 'verifyContact'];
  }

  showComponent(theme: any) {
    return <Game authData={this.props.authData} />;
  }
}

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'email',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 1,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 2,
    },
  ]
};

const errorMessageMap = (message: string) => {
  if (/CUSTOM_AUTH is not enabled for the client/i.test(message)) {
    return 'Please enter a password';
  }
  return message;
};

const AppWithAuth: React.FC = () => {
  return (
    <Authenticator
      authState="signIn"
      errorMessage={errorMessageMap}
      hideDefault={true}
      usernameAttributes={UsernameAttributes.EMAIL}
      hide={[Greetings, SignUp, ConfirmSignIn, ConfirmSignUp, RequireNewPassword, VerifyContact, ForgotPassword, TOTPSetup]}
    >
      <SignIn />
      <SignUp signUpConfig={signUpConfig} />
      <App />
    </Authenticator>
  );
};

export default AppWithAuth;
