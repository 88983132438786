import React from "react";
import {ClickParam} from "antd/lib/menu";
import {Button, Icon, Layout, Menu, Modal} from "antd";
import {SignOut} from "aws-amplify-react";

const { Sider } = Layout;

export default class GameSideMenu extends React.Component<any, any> {

  state = {
    collapsed: true,
    visible: false,
  };

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onMenuClick = ({ item, key, keyPath, domEvent }: ClickParam) => {
    if (key === "3") this.props.toggleChat();
    if (key === "4") this.showModal();
  };

  render() {
    const { visible } = this.state;
    return (
      <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
        <div style={{
          height: '32px',
          background: 'rgba(255, 255, 255, 0.2)',
          margin: '16px',
          fontWeight: 'bold',
          fontSize: '20px',
          color: 'white',
          textAlign: 'center'
        }} className="logo">W</div>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" onClick={this.onMenuClick}>
          <Menu.Item key="1">
            <Icon type="home" />
            <span>Home</span>
          </Menu.Item>
          <Menu.Item key="2">
            <Icon type="user" />
            <span>Character</span>
          </Menu.Item>
          <Menu.Item key="3">
            <Icon type="message" />
            <span>Chat</span>
          </Menu.Item>
          <Menu.Item key="4">
            <Icon type="setting" />
            <span>Settings</span>
          </Menu.Item>
        </Menu>
        <Modal
          visible={visible}
          title="WuxiaMMO Settings"
          centered
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Close
            </Button>
          ]}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <SignOut />
        </Modal>
      </Sider>
    );
  }
}
