import React from "react";
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import {Button, Form, Input, Radio} from "antd";

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class CreateCharacter extends React.Component<any, any> {

  state = {
    hasCharacter: false,
    nameExists: false,
  };

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.createCharacter(values);
      }
    });
  };

  async createCharacter(body: any) {
    try {
      const session = await Auth.currentSession();
      const jwtToken = session.getIdToken().getJwtToken();
      let apiName = 'AccountService';
      let path = '/character';
      let myInit = {
        body,
        headers: { Authorization: jwtToken }
      };
      const response = await API.post(apiName, path, myInit);
      const { hasCharacter, nameExists } = response;
      if (!hasCharacter && !nameExists) window.location.reload();
      else this.setState({ hasCharacter, nameExists });
    } catch (e) {
      console.error(e);
      this.setState({ initialLoad: true, hasError: true });
    }
  }

  render() {
    const { nameExists, hasCharacter } = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    const characterNameError = isFieldTouched('name') && getFieldError('name');
    const nameExistsError = nameExists ? 'This name is already taken, please choose a different one' : '';
    const hasCharacterError = hasCharacter ? 'You have already created a character, please reload the game' : '';
    return (
      <div style={{ backgroundColor: '#282c34', minHeight: '100vh', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent: 'center', fontSize: 'calc(10px + 2vmin)', color: 'white',}}>
        <div style={{ backgroundColor: 'white', padding: '50px', borderRadius: '10px' }}>
          <h2>Create Character</h2>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Form.Item label="Name" validateStatus={(hasCharacterError || nameExistsError || characterNameError) ? 'error' : ''} help={hasCharacterError || nameExistsError || characterNameError || ''}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter a name' }],
              })(<Input placeholder="Character name" />)}
            </Form.Item>
            <Form.Item label="Class">
              {getFieldDecorator('characterClass', {
                initialValue: "warrior",
                rules: [{ required: true, message: 'Please select a class' }],
              })(
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="warrior">Warrior</Radio.Button>
                  <Radio.Button value="priest">Priest</Radio.Button>
                  <Radio.Button value="mage">Mage</Radio.Button>
                  <Radio.Button value="archer">Archer</Radio.Button>
                </Radio.Group>,
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const CreateCharacterForm = Form.create({ name: 'character_create' })(CreateCharacter);

export default CreateCharacterForm;
